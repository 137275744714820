<template>
  <div class="flex flex-col h-full bg-blueprint overflow-hidden">
    <tool-bar :state="state" pane-mode="EXPORT" is-pane-expanded @toggle-pane="goBack">
      <div class="w-full h-full relative">
        <img v-if="exportPreviewSrc" :src="exportPreviewSrc" class="w-full h-full object-cover blur-lg">

        <div class="absolute inset-0 overflow-auto">
          <div v-if="!isExportInProgress" class="w-full min-h-full flex flex-col justify-between">
            <div />

            <div>
              <div class="flex justify-center">
                <div class="flex max-w-full overflow-x-auto no-scrollbar snap-x snap-mandatory scroll-smooth">
                  <div
                    v-for="(item, index) in formats"
                    ref="items"
                    :key="item.format"
                    class="snap-always snap-center bg-main rounded-2xl mx-2 sm:mx-3 md:mx-1 lg:mx-2 px-9 md:px-4 lg:px-9 py-8 border-2 border-transparent transition-colors"
                    :class="[
                      index === 0 ? 'ml-[50vw] sm:ml-[50vw] md:ml-auto' : null,
                      index === formats.length - 1 ? 'mr-[50vw] sm:mr-[50vw] md:mr-auto' : null,
                      isActive ? '' : 'opacity-50'
                    ]"
                  >
                    <div class="text-2xl font-semibold">{{ item.title }}</div>
                    <div class="text-pale mt-4">
                      <ul>
                        <li v-for="(feature, _key) in item.features" :key="_key">{{ feature }}</li>
                      </ul>
                    </div>
                    <button class="btn-sm w-56 sm:w-72 md:w-52 lg:w-56 mt-8 hover:bg-gradient-to-t hover:from-[#6187a1] hover:to-[#6b9bb2]" :disabled="!isActive" @click="clickOnRenderImage(index)">
                      {{ item.button }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="flex md:hidden justify-center mt-6">
                <a
                  v-for="(item, index) in formats"
                  :key="item.format"
                  class="rounded-full w-3 h-3 mx-2.5 bg-white cursor-pointer"
                  @click="jumpToItem(index)"
                />
              </div>

              <div class="mb-32 flex flex-col justify-center">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </tool-bar>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onUnmounted, watch, computed, shallowRef, Ref } from 'vue';
import { renderPreviewImage, renderExportImage } from '../panes';
import ToolBar from '../components/ToolBar.vue';
import { lastImage } from '../lastImage';
import { useRouter } from 'vue-router';
import {
  open as openLockScreen,
  close as closeLockScreen,
  progress as lockScreenProgress,
  message as lockScreenMessage
} from '../lockScreen';
import { downloadUrl, revokeBlobURLSoon } from '../downloadHelper';
import { open as showErrorPage } from '../errorPage';
import { isWorkstationAuthorized } from '../auth';
import { isActive } from '../user';
import { stateByImageId } from '../stateByImageId';
import { ImageSizeStructure, RenderFormats } from '../paneConstants';

const items: Ref<any | null> = ref(null);
const unwatch = watch(() => items.value, () => {
  if (items.value) {
    // FIXME this is weird, DIV element has no value array... because it's an array of refs
    items.value[1].scrollIntoView({ behavior: 'instant' });
    unwatch();
  }
});

const router = useRouter();

const { originalUrl, imageSize, imageId } = defineProps<{
  imageId: string;
  imageSize: ImageSizeStructure;
  originalUrl?: string;
}>();

const isExportInProgress = shallowRef(false);

const exportPreviewSrc: Ref<string|null> = ref(null);

onBeforeMount(async () => {
  exportPreviewSrc.value = originalUrl || null;

  const result = await renderPreviewImage({
    mode: 'settings',
    ...state
  });

  exportPreviewSrc.value = result.url;
});

const smallerImageSize = computed(() => {
  if (imageSize.width === null || imageSize.height === null) {
    return null;
  }

  const MAX_PX = 2160;

  if (imageSize.width > imageSize.height) {
    return {
      width: MAX_PX,
      height: Math.round((MAX_PX / imageSize.width) * imageSize.height)
    };
  }

  return {
    height: MAX_PX,
    width: Math.round((MAX_PX / imageSize.height) * imageSize.width)
  };
});

const formats = ref([
  {
    title: 'Lossless',
    features: [
      `Max resolution ${imageSize.width}×${imageSize.height}px`,
      'TIFF 16 bit'
    ],
    downloadingLabel: `Downloading lossless 16 bit TIFF`,
    button: 'Download',
    format: 'tiff',
    enabled: true
  },
  {
    title: 'Best quality JPEG',
    features: [
      `Max resolution ${imageSize.width}×${imageSize.height}px`,
      'JPEG 100%'
    ],
    downloadingLabel: `Downloading max quality JPEG`,
    button: 'Download',
    format: 'jpeg',
    enabled: true
  },
  {
    title: 'Optimised for web',
    features: [
      `Resized to ${smallerImageSize.value!.width}×${smallerImageSize.value!.height}px`,
      'JPEG 80%'
    ],
    downloadingLabel: `Downloading smaller JPEG for web`,
    button: 'Download',
    format: 'web'
  }
]);

const state: any = stateByImageId.value[imageId];

if (!state) {
  router.push({ name: 'imagePage', query: { i: imageId } });
}

async function clickOnRenderImage(formatIndex: number) {
  if (!isActive.value) {
    return;
  }

  if (formats.value[formatIndex].enabled === false) {
    return;
  }

  const format = formats.value[formatIndex].format as RenderFormats;

  lockScreenProgress.value = null;

  openLockScreen('Rendering…');
  isExportInProgress.value = true;

  const result = await renderExportImage(
    {
      mode: 'settings',
      ...state
    },
    format
  );

  if (result?.isDeauthorized) {
    isExportInProgress.value = false;
    closeLockScreen();
    alert("This workstation has been deauthorized");
    isWorkstationAuthorized.value = false;
    return;
  }

  if (!result?.success) {
    isExportInProgress.value = false;
    closeLockScreen();
    showErrorPage("Oops", "Renderer error", result.error);
    return;
  }

  lastImage.value = result.lastImage;

  lockScreenProgress.value = 0;
  lockScreenMessage.value = formats.value[formatIndex].downloadingLabel;

  downloadUrl(result.url, result.filename, format, goBack);
}

function goBack() {
  router.push({ name: 'imagePage', query: { i: imageId } });
}

onUnmounted(revokeBlobURLSoon);

function jumpToItem(index: number) {
  items.value[index].scrollIntoView({ behavior: 'smooth' });
}
</script>
