import { ShallowRef, shallowRef } from 'vue';
import ky from 'ky';
import { lastImage } from './lastImage.js';
import { UserPublic, UserResponse } from '../../backend/src/commonTypes.js';
import { isWorkstationAuthorized } from './auth.js';
import { API_PREFIX } from './constants.js';

export const isUser = shallowRef(false);
export const email: ShallowRef<string | null> = shallowRef(null);
export const id: ShallowRef<string | null> = shallowRef(null);
export const isActive = shallowRef(false);
export const subscriptionCurrentPeriodEnd: ShallowRef<number | null> = shallowRef(null);
export const didUseTrial = shallowRef(false);

export async function fetchWhoAmI() {
  const json: UserResponse = await ky.get(API_PREFIX + '/auth/user').json().catch(() => null) as UserResponse;
  if (!json?.success) {
    return false;
  }

  isWorkstationAuthorized.value = json.isWorkstationAuthorized || false;

  lastImage.value = json.lastImage || null;

  setUser(json.user);

  if (json.user && isWorkstationAuthorized.value) {
    resetWorkstationAuthCookie(); // fire-and-forget
  }

  return true;
}

async function resetWorkstationAuthCookie() {
  return await ky.get(
    API_PREFIX + '/auth/workstation/reset',
    {
      credentials: 'include'
    }
  ).catch(() => null);
}

export function setUser(user?: UserPublic) {
  // TODO: we want this to be a structure
  if (!user) {
    isUser.value = false;
    email.value = null;
    id.value = null;
    isActive.value = false;
    subscriptionCurrentPeriodEnd.value = null;
    didUseTrial.value = false;
    return;
  }

  isUser.value = true;
  email.value = user.email;
  id.value = user.id;
  isActive.value = user.isActive;
  subscriptionCurrentPeriodEnd.value = user.subscriptionCurrentPeriodEnd || null;
  didUseTrial.value = user.didUseTrial && true;
}
