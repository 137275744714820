<template>
  <div
    class="mx-auto"
    :class="{
      'cursor-pointer': state === 'idle',
      'aspect-square max-w-md': !wide,
    }"
    @click="errorMessage ? errorMessage = null : openFileDialog()"
  >
    <div
      class="flex justify-center items-center md:px-10 py-10 bg-[#2e2e2e] w-full h-full rounded-2xl"
      :class="{
        'dashed-border': state === 'idle',
        'dashed-border-dragging': state === 'idle' && isDragging,
        'py-20': wide
      }"
    >
      <div v-if="state === 'idle' && errorMessage" class="text-center text-danger">
        <icon-death class="mx-auto h-12 w-12" aria-hidden="true" />
        <div class="mt-4">
          <p>{{ errorMessage }}</p>
        </div>
        <div class="mt-4">
          <button class="link">Okay</button>
        </div>
      </div>

      <div v-else-if="state === 'idle'" class="text-center text-info">
        <div class="flex items-center justify-center space-x-2.5">
          <icon-image :class="isTrial ? 'size-16' : 'size-12'" aria-hidden="true" />
          <div v-if="isTrial" class="text-2xl font-black leading-none w-32 text-left">TRY NOW FOR FREE!</div>
        </div>
        <div class="mt-10">
          <p>Drag photo or click to upload</p>
        </div>
        <p class="">JPEG, HEIC, TIFF or DNG</p>
      </div>
    </div>
  </div>

  <input
    key="1"
    ref="uploader"
    type="file"
    class="hidden"
    accept="image/jpeg,image/x-adobe-dng,image/heif,image/heic,image/avif,image/webp,image/tiff,image/png"
    @change="onFileUploadChange"
  >
</template>


<script setup lang="ts">
import { ref, computed, shallowRef, watch, onBeforeUnmount, Ref, ShallowRef } from 'vue';
import { ALLOWED_FILE_TYPES, getMimeTypeByFilename, uploadSingle, UploadOutput, getMaxFileSizeByMimeType, uploadMultiple } from '../fileTools';
import IconImage from './icons/IconImage.vue';
import IconDeath from './icons/IconDeath.vue';
import { API_PREFIX } from '../constants';
import { post } from '../post';
import { open as openLockScreen, close as closeLockScreen, progress as lockScreenProgress, message as lockScreenMessage } from '../lockScreen';
import { open as showErrorPage } from '../errorPage';

const props = defineProps<{
  isTrial?: boolean;
  wide?: boolean;
  dropTarget: HTMLElement | null;
}>();

const emit = defineEmits<{
  uploaded: [string]
}>();

let updateProcessingInterval: number | null = null;

const lastPercentComplete = shallowRef<number>(0); // for throttling only

const uploader: Ref<HTMLInputElement | null> = ref(null);

type States = 'idle' | 'preparing' | 'uploading' | 'uploaded';
const state: ShallowRef<States> = shallowRef('idle');

const isUploading = computed(() => state.value !== 'idle');

const errorMessage: ShallowRef<string | null> = shallowRef(null);

let isDragging = shallowRef<boolean>(false);

watch(props, () => {
  if (!props.dropTarget) {
    return;
  }

  props.dropTarget.addEventListener('dragenter', setDropzoneActive);
  props.dropTarget.addEventListener('dragover', setDropzoneActive);
  props.dropTarget.addEventListener('dragleave', setDropzoneInactive);
  props.dropTarget.addEventListener('drop', onDrop);
}, { immediate: true });

onBeforeUnmount(() => {
  if (updateProcessingInterval !== null) {
    clearInterval(updateProcessingInterval);
  }

  if (!props.dropTarget) {
    return;
  }

  props.dropTarget.removeEventListener('dragenter', setDropzoneActive);
  props.dropTarget.removeEventListener('dragover', setDropzoneActive);
  props.dropTarget.removeEventListener('dragleave', setDropzoneInactive);
  props.dropTarget.removeEventListener('drop', onDrop);
});

defineExpose({ isUploading, isDragging, openFileDialog });

watch(isDragging, v => console.log(v));

function setDropzoneActive(e: Event) {
  e.preventDefault();
  isDragging.value = true;
}

function setDropzoneInactive(e: Event) {
  e.preventDefault();
  isDragging.value = false;
}

function openFileDialog() {
  if (state.value !== 'idle') {
    return;
  }

  if (uploader.value === null) {
    return;
  }

  uploader.value.click();
}

function onDrop(e: Event) {
  e.preventDefault();
  isDragging.value = false;

  // @ts-expect-error - TS doesn't know about DataTransfer
  upload(e.dataTransfer.files[0]);
}

function onFileUploadChange(e: Event) {
  e.preventDefault();
  // @ts-expect-error - TS doesn't know about FileList
  upload(e.target.files[0]);
}

function updateProgress(loaded: number, total: number) {
  const percentComplete = Math.round(loaded * 100 / total);
  if (percentComplete === lastPercentComplete.value) {
    return;
  }

  lastPercentComplete.value = percentComplete;
  lockScreenProgress.value = percentComplete * 0.8;
}

async function upload(file: File) {
  lastPercentComplete.value = 0;

  if (state.value !== 'idle') {
    return;
  }

  if (!file) {
    return;
  }

  const mimetype = getMimeTypeByFilename(file.name!); // because File.type is empty sometimes in Chrome

  if (!ALLOWED_FILE_TYPES[mimetype]) {
    errorMessage.value = 'Invalid file type';
    return;
  }

  if (file.size > getMaxFileSizeByMimeType(mimetype)) {
    errorMessage.value = 'File is too large';
    return;
  }

  errorMessage.value = null;

  state.value = 'preparing';

  openLockScreen('Preparing upload', 0);

  const { json: jsonGenerateSignedUrl, status: statusGenerateSignedUrl } = await post({
    url: API_PREFIX + '/upload/prepare',
    data: {
      mimetype,
      size: file.size
    }
  });

  if (!jsonGenerateSignedUrl?.success) {
    state.value = 'idle';

    if (statusGenerateSignedUrl === 400) {
      closeLockScreen();
      showErrorPage("400", "Invalid file type", "Server doesn't support this file type. How could this happen?");

    } else {
      closeLockScreen();
      showErrorPage("Oops", "Upload error", jsonGenerateSignedUrl?.error || "Unknown error");
    }

    return;
  }

  // this is a failsafe fix, should never happen; but when this happens, frontend starts and infinite cycle,
  // so we have to protect the user from this
  if (jsonGenerateSignedUrl.isMultipart && jsonGenerateSignedUrl.urls.length === 0) {
    state.value = 'idle';
    closeLockScreen();
    showErrorPage("500", "Internal server error");
    return;
  }

  state.value = 'uploading';

  lockScreenMessage.value = 'Uploading ' + file.name;

  let uploadResponse: UploadOutput | null = null;

  if (jsonGenerateSignedUrl.isMultipart) {
    try {
      uploadResponse = await uploadMultiple({
        file,
        chunkSize: jsonGenerateSignedUrl.chunkSize,
        url: jsonGenerateSignedUrl.urls,
        onProgress: updateProgress
      });
    } catch (e) {
      console.log("FAILED");
      console.log(e);
    }

  } else {
    try {
      uploadResponse = await uploadSingle({
        file,
        url: [ jsonGenerateSignedUrl.url ],
        onProgress: updateProgress
      });
    } catch (e) {
      console.log("FAILED");
      console.log(e);
    }
  }

  if (!uploadResponse) {
    state.value = 'idle';
    closeLockScreen();
    showErrorPage("Oops", "Upload error", "Error: " + uploadResponse);
    return;
  }

  state.value = 'uploaded';

  lockScreenMessage.value = 'Processing ' + file.name;

  updateProcessingInterval = setInterval(() => {
    if (lockScreenProgress.value! >= 100) {
      clearInterval(updateProcessingInterval!);
      updateProcessingInterval = null;
      return;
    }

    lockScreenProgress.value! += 0.8;
  }, 300);

  // Now we have to employ promises and not await because we must let the screen render into 'uploaded' state.
  // Which somehow does not happen if we await here.

  const imageId: string = jsonGenerateSignedUrl.imageId;

  const { json: jsonUploaded, status: statusUploaded } = await post({
    url: API_PREFIX + '/upload/finish',
    isImageRequest: true,
    data: {
      imageId,
      uploadId: jsonGenerateSignedUrl.uploadId,
      etags: uploadResponse?.etags,
      filename: file.name
    }
  });

  closeLockScreen();

  if (updateProcessingInterval !== null) {
    clearInterval(updateProcessingInterval);
    updateProcessingInterval = null;
  }

  if (!jsonUploaded?.success) {
    state.value = 'idle';

    if (statusUploaded === 403) {
      showErrorPage("403", "Image already exists");

    } else if (statusUploaded === 500) {
      showErrorPage("500", "Internal server error");

    } else if (statusUploaded === 999) {
      showErrorPage("Timeout", "We have spent too much time and failed.");

    } else {
      showErrorPage("Oops", "Upload error", jsonUploaded?.error || "Unknown error");
    }

    return;
  }

  emit('uploaded', imageId);
}
</script>
